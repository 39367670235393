import { useState } from "preact/hooks";

const LoginModal = ({
  isOpen,
  onClose,
  onLogin,
  username,
  password,
  setUsername,
  setPassword,
  error,
}: {
  isOpen: boolean;
  onClose: () => void;
  onLogin: () => void;
  username: string;
  password: string;
  setUsername: (value: string) => void;
  setPassword: (value: string) => void;
  error: string | null;
}) => {
  if (!isOpen) return null;

  return (
    <div
      id="login-modal-background"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={onClose}
    >
      <div
        class="bg-white rounded-lg shadow-lg p-4 w-full max-w-md"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 class="text-xl font-bold">Login</h2>

        {error && <p class="text-red-500">{error}</p>}

        {/* Input fields for username and password */}
        <label>
          Username:
          <input
            type="text"
            value={username}
            onInput={(e) => setUsername(e.currentTarget.value)}
            class="border border-gray-300 p-2 w-full"
            placeholder="Username"
          />
        </label>

        <label>
          Password:
          <input
            type="password"
            value={password}
            onInput={(e) => setPassword(e.currentTarget.value)}
            class="border border-gray-300 p-2 w-full"
            placeholder="Password"
          />
        </label>

        <div class="mt-4 flex justify-end space-x-2">
          <button
            class="bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            onClick={onLogin}
          >
            Log in
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
